@use "sass:map";
@import './colors';

lightbox {
    box-shadow: none !important;
    background-color: map.get($light, gray, 60);
    padding: 0;

    @include mq(large) {
      padding: 10px;
    }

    gallery {
      background: transparent;

      * {
        @include mq(medium) {
          box-sizing: unset;
        }
      }

      .g-box {
        padding-bottom: 50px;
      }

      .g-box, gallery-slider {
        flex: 1;
        padding-top: 50px;

        @include mq(medium) {
          flex: 0;
        }
      }

      gallery-core {
        justify-content: space-around;
        padding: 30px 0px;

        @include mq(medium) {
          justify-content: unset;
          padding: auto;
        }
      }

      gallery-slider {
        margin-top: 0;
        padding-top: 0;

        i[role=button] {
          opacity: 1;
        }
      }

      .g-box-template {
        pointer-events: none;
        top: -10px;
        z-index: map-get($z-index-map, modal);
        color: map-get($light, black, 100p);
        width: 100%;
        height: 100%;

        @include mq(medium) {
          text-align: center;
          color: map-get($light, white);
        }
      }

      .g-box-info {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
      }

      gallery-core {
        gap: 20px;
      }

      .lightbox-title {
        margin: 10px 16px;
        color: map-get($light, text, tint);
      }

      gallery-item > gallery-image {
        overflow: hidden;

        img {
          transition: transform 0.25s ease;
        }
      }
    }
    .g-btn-close {
      top: 16px;
      right: 10px;
      padding: 8px;
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;

      &:focus,
      &:hover {
        background-color: map-get($light, primary, shade, 4p);
      }

      @include mq(medium) {
        padding-top: 0;
        top: -6px;
      }

      @include mq(large) {
        top: 6px;
      }

      svg {
        opacity: 1;
        width: 15px;
        height: 15px;
        filter: unset;
      }

      svg path {
        fill: map-get($light, text, tint)!important;
      }

    }
  }

  .g-backdrop {
    background-color: map-get($light, white) !important;

    @include mq(medium) {
      background-color: map-get($light, primary, shade, backdrop-50p) !important;
    }
  }

  .g-slider-content {
    gap: 8px !important;

    @include mq(medium) {
      gap: 20px !important;
    }
  }

  gallery-core[imageSize=cover] gallery-item .g-image-item {
    width: 764px !important;
  }

  .lightbox__hint-text {
    display: none;
    @include mq(medium) {
      display: block;
    }
  }