@use "sass:map";
@import '../_colors';

.success-toast {
  --mdc-snackbar-container-color: #{map.get($light, success, tint)};
  --mdc-snackbar-supporting-text-color: #{map.get($light, success, dark)};
}

.error-toast {
  --mdc-snackbar-container-color: #{map.get($light, error, tint)};
  --mdc-snackbar-supporting-text-color: #{map.get($light, error, dark)};
}
