@use "sass:map";
@import "./mixins";

// Typography

$typography: (
  h1: (
    font-size: 60px,
    font-weight: 400,
    line-height: 110%, /* 66px */
    letter-spacing: -1.5px,
  ),
  h2: (
    font-size: 48px,
    font-weight: 500,
    line-height: 110%, /* 52.8px */
    letter-spacing: -0.5px,
  ),
  h3: (
    font-size: 40px,
    font-weight: 500,
    line-height: 110%, /* 44px */
  ),
  h4: (
    font-size: 32px,
    font-weight: 500,
    line-height: 110%, /* 35.2px */
    letter-spacing: 0.25px,
  ),
  h5: (
    font-size: 24px,
    font-weight: 500,
    line-height: 150%, /* 36px */
  ),
  h6: (
    font-size: 20px,
    font-weight: 500,
    line-height: 150%, /* 30px */
    letter-spacing: 0.15px,
  ),
  body-1: (
    font-size: 16px,
    font-weight: 400,
    line-height: 150%, /* 21px */
    letter-spacing: 0.15px,
  ),
  body-1-medium: (
    font-size: 16px,
    font-weight: 500,
    line-height: 150%, /* 21px */
    letter-spacing: 0.15px,
  ),
  body-2: (
    font-size: 14px,
    font-weight: 400,
    line-height: 150%, /* 21px */
    letter-spacing: 0.15px,
  ),
  body-2-medium: (
    font-size: 14px,
    font-weight: 500,
    line-height: 150%, /* 21px */
    letter-spacing: 0.15px,
  ),
  subtitle-1-medium: (
    font-size: 18px,
    font-weight: 500,
    line-height: 160%, /* 28.8px */
  ),
  subtitle-2-medium: (
    font-size: 16px,
    font-weight: 500,
    line-height: 160%, /* 28.8px */
  ),
  caption: (
    font-size: 12px,
    font-weight: 400,
    line-height: 160%, /* 19.2px */
    letter-spacing: 0.4px,
  ),
  overline: (
    font-size: 12px,
    font-weight: 400,
    line-height: 160%, /* 19.2px */
    letter-spacing: 1px,
    text-transform: uppercase,
  ),
  badge-label: (
    font-size: 12px,
    font-weight: 500,
    line-height: 20px, /* 166.667% */
    letter-spacing: 0.14px,
  ),
  button-xlarge: (
    font-size: 20px,
    font-weight: 500,
    line-height: 40px, /* 200% */
    letter-spacing: 0.46px,
  ),
  button-large: (
    font-size: 18px,
    font-weight: 500,
    line-height: 32px, /* 177.778% */
    letter-spacing: 0.46px,
  ),
  button-medium: (
    font-size: 16px,
    font-weight: 500,
    line-height: 26px, /* 162.5% */
    letter-spacing: 0.4px,
  ),
  button-small: (
    font-size: 14px,
    font-weight: 500,
    line-height: 24px, /* 171.429% */
    letter-spacing: 0.4px,
  ),
  button-xsmall: (
    font-size: 12px,
    font-weight: 500,
    line-height: 20px, /* 166.667% */
  ),
  input-text: (
    font-size: 16px,
    font-weight: 400,
    line-height: 24px, /* 150% */
    letter-spacing: 0.15px,
  ),
  input-label: (
    font-size: 12px,
    font-style: normal,
    font-weight: 400,
    line-height: 12px, /* 100% */
    letter-spacing: 0.15px,
  ),
  link-medium: (
    font-size: 16px,
    font-weight: 400,
    line-height: 150%, /* 24px */
    letter-spacing: 0.15px,
    text-decoration-line: underline,
  ),
  link-small: (
    font-size: 14px,
    font-weight: 400,
    line-height: 150%, /* 21px */
    letter-spacing: 0.15px,
    text-decoration-line: underline,
  ),
  helper-text: (
    font-size: 12px,
    font-style: normal,
    font-weight: 400,
    line-height: 16px, /* 133.333% */
    letter-spacing: 0.4px,
  )
);

@mixin typography($type, $important: false) {
  font-family: Roboto if-important($important);
  font-style: normal if-important($important);
  @include map-to-styles(map-get($typography, $type), $important)
}

@for $i from 1 through 6 {
  h#{$i} {
    @include typography(h#{$i});
  }
}

@each $key, $value in $typography {
  .#{$key} {
    @include typography($key);
  }
}

body,
.body-1 {
  @media (max-width: 768px) {
    font-size: 14px;
  }
}

body {
  @include typography(body-1);
}

sup {
  @include typography(caption);
}
