@use "sass:color";
// A list of colors to be used in the app

$light: (
  white: #FFF,
  white-alpha: (
    10p: color.change(#FFF, $alpha: 0.1),
    12p: color.change(#FFF, $alpha: 0.12),
    20p: color.change(#FFF, $alpha: 0.2),
    30p: color.change(#FFF, $alpha: 0.3),
    40p: color.change(#FFF, $alpha: 0.4),
    50p: color.change(#FFF, $alpha: 0.5),
    60p: color.change(#FFF, $alpha: 0.6),
    70p: color.change(#FFF, $alpha: 0.7),
    80p: color.change(#FFF, $alpha: 0.8),
    90p: color.change(#FFF, $alpha: 0.9),
  ),
  ref: (
    white: #FFF,
  ),
  black: (
    12p: color.change(#000, $alpha: 0.12),
    100p: color.change(#000, $alpha: 1),
  ),
  blue: (
    1: #F1F2FF,
    10: #DEE0FF,
    20: #BBC3FF,
    30: #97A5FF,
    40: #7387FF,
    50: #4D67FF,
    60: #2848EE,
    70: #002CCD,
    80: #001D93,
    90: #001678,
  ),
  gray: (
    1: #F8F8F8,
    10: #E5E5E5,
    20: #CCCCCC,
    30: #B2B2B2,
    40: #999999,
    50: #808080,
    60: #666666,
    70: #4C4C4C,
    80: #323232,
    90: #191919,
  ),
  text: (
    primary: #191919,
    secondary: #666666,
    disabled: #999999,
    tint: #E5E5E5,
  ),
  primary: (
    main: #4C4C4C,
    dark: #191919,
    light: #808080,
    lighter: #D9D9D9,
    tint: #F1F1F1,
    shade: (
      4p: color.change(#4C4C4C, $alpha: 0.04),
      8p: color.change(#4C4C4C, $alpha: 0.08),
      12p: color.change(#4C4C4C, $alpha: 0.12),
      30p: color.change(#4C4C4C, $alpha: 0.3),
      50p: color.change(#4C4C4C, $alpha: 0.5),
      backdrop-50p: color.change(#000000, $alpha: 0.5),
      light-12p: color.change(#6F8DE3, $alpha: 0.12),
    ),
    contrast: #FFFFFF,
  ),
  secondary: (
    main: #2C4D9E,
    dark: #00215F,
    light: #6F8DE3,
    lighter: #CFD8F6,
    tint: #EEF1FC,
    shade: (
      4p: color.change(#2C4D9E, $alpha: 0.04),
      8p: color.change(#2C4D9E, $alpha: 0.08),
      12p: color.change(#2C4D9E, $alpha: 0.12),
      30p: color.change(#2C4D9E, $alpha: 0.3),
      50p: color.change(#2C4D9E, $alpha: 0.5),
      light-12p: color.change(#6F8DE3, $alpha: 0.12),
    ),
  ),
  success: (
    main: #2E7D32,
    dark: #1B5E20,
    light: #4CAF50,
    tint: #EAF5EA,
  ),
  error: (
    main: #D32F2F,
    dark: #C62828,
    light: #EF5350,
    tint: #FDEAEA,
  ),
  action: (
    active: color.change(#000000, $alpha: 0.54),
    hover: color.change(#000000, $alpha: 0.04),
    selected: color.change(#000000, $alpha: 0.08),
    focus: color.change(#000000, $alpha: 0.12),
    disabled: color.change(#000000, $alpha: 0.26),
  ),
  info: (
    dark: #01579B,
    tint: #E1F5FE,
  ),
  warning: (
    dark: #E65100,
    tint: #FFF3E0,
  ),
  background: (
    white: #FFFFFF,
    paper: #FAFAFA,
    sky: #F7F8FE,
    dark: #323232,
    black: #000000,
  ),
  other: (
    divider: color.change(#000000, $alpha: 0.12),
    outlined-border: color.change(#000000, $alpha: 0.23),
    tooltip: color.change(#616161, $alpha: 0.9),
    rating-active: #FFB300,
    rating-hover: #E87511,
    rating-disabled: #E87511,
  ),
);

$spring: (
  ref: (
    white: (
      30p: color.change(#ffffff, $alpha: 0.30),
    )
  ),
);

@mixin build-color-classes($map, $className: '') {
  @each $key, $value in $map {
    $newClassName: $className + if($className != '', '-', '') + $key;
    @if type-of($value) == map {
      @include build-color-classes($value, $newClassName);
    } @else {
      .#{$newClassName} {
        color: $value;
      }
    }
  }
}

@mixin build-color-variables($map, $variableName: '') {
  @each $key, $value in $map {
    $newVariableName: $variableName + if($variableName != '', '-', '') + $key;
    @if type-of($value) == map {
      @include build-color-variables($value, $newVariableName);
    } @else {
      --#{$newVariableName}: #{$value};
    }
  }
}
