@use "sass:map";
@import "./mixins";

// Elevation

$elevation: (
  2: (
    box-shadow: (0px 1px 5px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.20)),
  ),
  3: (
    box-shadow: (0px 1px 8px 0px rgba(0, 0, 0, 0.12), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 3px 3px -2px rgba(0, 0, 0, 0.20)),
  ),
  5: (
    box-shadow: (0px 1px 3px 0px rgba(0, 0, 0, 0.12), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.20)),
  )
);

@mixin elevation($level) {
  @include map-to-styles(map-get($elevation, $level))
}

$levels: 2, 3, 5;
@each $level in $levels {
  .elevation-#{$level} {
    @include elevation($level);
  }
}
