@use 'sass:map';
@import 'src/assets/colors';
@import 'src/assets/typography';

:root {
  --mdc-theme-error: #{map-get($light, error, main)};
  --mdc-filled-text-field-error-focus-label-text-color:#{map-get($light, error, main)};
  --mdc-filled-text-field-error-label-text-color:#{map-get($light, error, main)};
  --mdc-filled-text-field-error-caret-color:#{map-get($light, error, main)};
  --mdc-filled-text-field-error-active-indicator-color:#{map-get($light, error, main)};
  --mdc-filled-text-field-error-focus-active-indicator-color:#{map-get($light, error, main)};
  --mdc-filled-text-field-error-hover-active-indicator-color:#{map-get($light, error, main)};
  --mdc-outlined-text-field-error-caret-color:#{map-get($light, error, main)};
  --mdc-outlined-text-field-error-focus-label-text-color:#{map-get($light, error, main)};
  --mdc-outlined-text-field-error-label-text-color:#{map-get($light, error, main)};
  --mdc-outlined-text-field-error-focus-outline-color:#{map-get($light, error, main)};
  --mdc-outlined-text-field-error-hover-outline-color:#{map-get($light, error, main)};
  --mdc-outlined-text-field-error-outline-color:#{map-get($light, error, main)};
  --mat-form-field-error-text-color:#{map-get($light, error, main)};
  --mdc-filled-text-field-error-focus-label-text-color: #{map-get($light, error, main)};
  --mdc-outlined-text-field-error-label-text-color: #{map-get($light, text, secondary)};
}
.select-outlined {
  --mat-form-field-state-layer-color: transparent !important;
  --mat-select-enabled-trigger-text-color: #{map-get($light, text, secondary)};
  --mat-select-trigger-text-tracking: 0.15px;

  &__option {
    .mat-pseudo-checkbox {
      display: none;
    }
  }

  .mat-mdc-text-field-wrapper {
    background-color: transparent;
    align-items: center;
    border: 1px solid map-get($light, other, outlined-border);
    @include typography(input-text);
    .mat-mdc-form-field-infix {
      width: unset;
    }
    &.mdc-text-field--focused {
      border-color: map-get($light, primary, main);
    }
  }
  .mat-mdc-select-trigger {
    gap: 30px;
  }
  .mat-mdc-select-arrow svg {
    fill: #{map-get($light, action, active)};
  }
  .mat-mdc-form-field-subscript-wrapper,
  .mdc-line-ripple::before {
    display: none;
  }

  > .mat-mdc-text-field-wrapper.mdc-text-field--filled:not(.mdc-text-field--disabled) {
      background-color: transparent;
  }

  .mat-mdc-text-field-wrapper.mdc-text-field {
      padding: 0 12px 0 14px;
      border-radius: 4px;
  }

  .mdc-line-ripple {
      display: none;
  }

  &:hover .mat-mdc-form-field-focus-overlay {
      opacity: 0;
  }

  &:hover .mat-mdc-text-field-wrapper.mdc-text-field--filled:not(.mdc-text-field--disabled) {
      border-color: map-get($light, primary, main);
  }

  &.select-xlarge {
      height: 56px;
  }
  &.select-medium {
      height: 42px;
  }
  &.select-small {
      height: 32px;
  }

  &.mat-focused .mat-mdc-form-field-focus-overlay {
      opacity: 0;
  }
}

.mat-focused .mat-mdc-text-field-wrapper.mdc-text-field--filled:not(.mdc-text-field--disabled),
.mat-focused {
    border-width: 2px;
    --mdc-outlined-text-field-focus-outline-color: map-get($light, primary, main);
    --mdc-outlined-text-field-focus-label-text-color: map-get($light, primary, main);
}