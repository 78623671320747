@use "sass:map";
@import 'normalize.css';
@import '@angular/material/prebuilt-themes/indigo-pink.css';
@import './assets/foundation.scss';
@import './assets/typography';
@import './assets/mixins';
@import './assets/zindex';
@import './assets/icons';
@import './assets/_lightbox';
@import './assets/_inputs';
@import './assets/_colors';
@import './assets/components/currency';
@import './assets/components/toast';
@import './assets/components/dialog';
@import './assets/components/chips';
@import './assets/components/search-page';

@mixin build-map($map) {
  @each $key, $value in $map {
    --#{$key}: #{$value};
  }
}

// Admin styles
$admin-styles: (
  admin-top-bar-height: 50px,
  admin-menu-width: 300px,
  admin-background-color: #ededed,
);

// Global styles
$global-styles: (
  //layout
  page-width: 1440px,
  gutter: 24px,
  page-background: map-get($light, white),

  // swiper styles
  swiper-pagination-color: #{map.get($light, primary, contrast)},
  swiper-pagination-bullet-inactive-color: #{map.get($light, gray, 20)},
  swiper-pagination-bullet-size: 6px,
);

// Base theme definitions
$light-theme-map: (
  // Badges
  theme-badge-bg-color: map-get($light, secondary, main),
  theme-badge-font-color: map-get($light, white),

  // buttons
  theme-button-contained-primary-bg-color: map-get($light, primary, main),
  theme-button-contained-primary-font-color: map-get($light, white),
  theme-button-contained-secondary-bg-color: map-get($light, secondary, main),
  theme-button-contained-secondary-font-color: map-get($light, white),
  theme-button-outlined-primary-color: map-get($light, primary, main),
  theme-button-outlined-secondary-color: map-get($light, secondary, main),

  // Content links
  theme-link-color-primary: map-get($light, text, primary),
  theme-link-color-secondary: map-get($light, secondary, main),

  // Price
  theme-price-color: map-get($light, secondary, main),

  // typography
  theme-headings-font-family: '"Roboto", sans-serif',
  theme-body-font-family: '"Roboto", sans-serif',
);

$dark-theme-map: $light-theme-map;

body {
  @include build-map($global-styles);
  @include build-map($admin-styles);
  @include build-color-variables($light, 'light');

  &:has(.sidebar.show-mobile.is-stuck) {
    overflow: hidden;

    @include mq(medium) {
      overflow: auto;
    }
  }
}

.light-theme {
  @include build-map($light-theme-map);
}

.dark-theme {
  @include build-map($dark-theme-map);
}

.container {
  max-width: var(--page-width);
  padding: 0 16px;
  margin: 0 auto;

  @include mq(medium) {
    padding: 0 32px;
  }

  @include mq(large) {
    padding: 0 80px;
  }
}

.hide,
.is-hidden {
  display: none !important;
}

.no-shrink {
  flex-shrink: 0;
}

.cdk-overlay-container {
  z-index: map-get($z-index-map, settings);
}


.grecaptcha-badge {
  z-index: map-get($z-index-map, recaptcha);
}

.mat-form-field--clear {
  --mat-form-field-container-height: 30px;
  --mat-form-field-container-vertical-padding: 0;
  background-color: transparent;

  .mat-mdc-input-element {
    display: none !important;
  }
  .mat-mdc-notch-piece {
    border: none !important;
  }
  .mdc-text-field {
    padding: 0 !important;
  }
}

// Firefox-only fix for a bug where sticky elements are not
// positioned correctly within fixed containers
// https://bugzilla.mozilla.org/show_bug.cgi?id=1437761
@-moz-document url-prefix() {
  .cdk-global-scrollblock {
    position: absolute;
    overflow-y: hidden;
    top: 0 !important;
  }
}

@include build-color-classes($light, 'light');
