@import 'src/assets/globals';

@mixin mq($mq-breakpoint, $mq-breakpoints: $breakpoints) {
    // If $mq-breakpoint is a key that exists in
    // $mq-breakpoints, get and use the value
    @if map-has-key($mq-breakpoints, $mq-breakpoint) {
        $mq-breakpoint: map-get($mq-breakpoints, $mq-breakpoint);
    }

    @media screen and (min-width: #{$mq-breakpoint}) {
        @content;
    }
}

@function if-important($important){
    @return #{if($important, '!important', '')};
}

@mixin map-to-styles($map, $important: false) {
    @each $key, $value in $map {
        #{$key}: #{$value} if-important($important);
    }
}
